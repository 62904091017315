@tailwind components;

@layer components {
  .app-container {
    @apply h-full w-full flex flex-col justify-center items-center;
    @apply border-t-0;
  }

  .skill-grid {
    @apply grid;
    grid-template-columns: 1fr 2fr;
  }

  .hero-button {
    @apply flex justify-center items-center flex-row  py-2 px-6 rounded cursor-pointer;
    @apply w-max;
    @apply font-bold text-white;
    @apply bg-accent2 dark:bg-accent2-dark;
    @apply transition-all duration-500;
    @apply hover:text-white hover:shadow;
  }

  .badge {
    @apply rounded inline-block align-middle;
    @apply font-sans2 font-bold text-xs whitespace-nowrap uppercase;
    @apply text-gray-500 dark:text-white;
    @apply bg-gray-300 dark:bg-gray-600;
    @apply mr-2 mb-2 py-1 px-4;
    @apply shadow;
  }

  .uk {
    @apply align-middle inline-block;
    width: 35px;
    height: 35px;
  }

  .header-portrait,
  .portrait {
    @apply object-cover rounded-full md:w-72 md:h-72 w-48 h-48 md:mb-0 mb-4;
  }

  .hand-wave {
    @apply bottom-10 left-0;
    cursor: grab;
  }

  .light-link a {
    @apply cursor-pointer transition duration-100;
    @apply text-white dark:text-white-dark;
    @apply hover:text-white dark:hover:text-white-dark;
  }

  .light-link a:hover {
    @apply filter drop-shadow;
  }

  nav .nav-item {
    @apply py-4 z-30;
  }

  nav .page-link {
    @apply px-4 py-2 rounded;
    @apply hover:bg-white hover:text-accent1-dark;
  }

  .fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 2s ease-out;
    will-change: opacity, visibility;
  }

  .fade-in-section.is-visible {
    opacity: 1;
    visibility: visible;
    display: flex;
  }
}
