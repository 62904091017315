@tailwind base;

@layer base {
  html,
  body {
    @apply m-0 p-0;
  }

  body {
    @apply font-sans text-lg;
    @apply font-normal;
    @apply dark:font-light;
    @apply bg-white text-black;
    @apply dark:bg-black-dark dark:text-white-dark;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }

  body.scroll-lock {
    @apply fixed overflow-y-scroll w-full;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-header m-0 mb-4 mt-12;
  }

  h1 {
    @apply text-4xl font-bold mb-8;
  }

  h2 {
    @apply text-2xl font-semibold;
  }

  h3 {
    @apply font-semibold;
  }

  h4 {
    @apply text-gray-500 text-xl font-light dark:text-gray-300 mb-0;
  }

  p {
    @apply m-0 mb-4;
  }

  a {
    @apply text-accent1 no-underline hover:text-accent1-dark dark:hover:text-white;
  }

  ul {
    @apply list-disc list-inside my-4;
  }

  ul.no-style {
    @apply list-none m-0 p-0;
  }

  button {
    @apply select-none;
  }

  strong {
    @apply font-black;
  }
}
